import { vuexfireMutations } from 'vuexfire'
import { firestore } from '@/plugins/firestore'

export const mutations = {
  ...vuexfireMutations,

  updatePhoneNumber(state, { phoneNumber }) {
    state.phoneNumber = phoneNumber
  },
  updateVerCode(state, { verCode }) {
    state.verCode = verCode
  },
  updateUserId(state, { userID }) {
    state.userID = userID
  },
  updateDriverMessages(state, { driverMes }) {
    state.driverMes = driverMes
  },
  updateOperatorMessages(state, { opMes }) {
    state.opMes = opMes
  },
  updateTimeBuffer(state, { timeBuffer }) {
    state.timeBuffer = timeBuffer
  }
}

export const state = () => ({
  phoneNumber: '',
  verCode: '',
  userID: '',
  driverMes: '',
  opMes: '',
  timeBuffer: ''
})

export const actions = {
  setOperatorAvailability: ({ state }, { available }) => {
    if (state.auth && state.auth.user && state.auth.user.id) {
      const data = { id: state.auth.user.id, available }
      return firestore
        .collection('operators_natori_dev')
        .doc(`operator${state.auth.user.id}`)
        .set(data)
        .then(() => {
          return data.id
        })
    }
    return Promise.reject(new Error('Not authorized'))
  }
}
// console.log(state.auth.user.id)
// export const exportedUserId = {state.auth.user.id
